import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
    return (
        <header className="main-header">
            <div className="header-content">
                <Link to="/" className="logo">
                    <h1>Sonic Simulations</h1>
                </Link>
                <nav>
                    <Link to="/" className="nav-link">Home</Link>
                    <Link to="/editor" className="nav-link">Create Simulation</Link>
                </nav>
            </div>
        </header>
    );
}

export default Header;