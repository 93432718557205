// components/Ball.js
import * as planck from 'planck-js';

export class Ball {
    constructor(world, x, y, radius, options, scale) {
        this.type = "ball";
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.options = options || {};
        this.scale = scale;

        this.body = world.createBody({
            type: 'dynamic',
            position: planck.Vec2(x, y)
        });

        const restitution = this.options && this.options.restitution !== undefined ? this.options.restitution : 1;

        this.body.createFixture(planck.Circle(radius), {
            density: 1,
            restitution: restitution
        });
        console.log("YYYY")
        console.log(this.options);

        if (this.options && this.options.initialVelocity) {
            console.log("XXXXX")
            this.body.setLinearVelocity(planck.Vec2(
                this.options.initialVelocity.x,
                this.options.initialVelocity.y
            ));
        }
    }

    draw(p) {
        let position = this.body.getPosition();
        p.fill(this.options.color || 'red');
        p.noStroke();
        p.ellipse(position.x * this.scale, position.y * this.scale, this.radius * this.scale * 2, this.radius * this.scale * 2);
    }

    playSound(synth) {
        // Implement sound playing logic here if needed
    }
}