import { useRef, useEffect } from 'react';
import p5 from 'p5';

export const useP5 = (sketch) => {
    const p5ContainerRef = useRef();
    const p5InstanceRef = useRef();

    useEffect(() => {
        p5InstanceRef.current = new p5(sketch, p5ContainerRef.current);

        return () => {
            if (p5InstanceRef.current) {
                p5InstanceRef.current.remove();
            }
        };
    }, [sketch]);

    return p5ContainerRef;
};