import * as planck from 'planck-js';
import * as Tone from 'tone';

export class Rectangle {
    constructor(world, x, y, width, height, options, scale) {
        console.log(options);
        this.type = "rectangle";
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.options = {...options};  // Create a shallow copy to avoid modifying the input
        this.originalColor = this.options.color;
        this.scale = scale;
        this.originalLives = this.options.lives || 0;  // 0 means unbreakable
        this.lives = this.originalLives;
        this.soundType = options.soundType || 'tone'; // Default to 'tone' if not specified
        this.sound = options.sound; // No default, will be undefined if not specified

        this.body = world.createBody({
            type: options.type || 'static',
            position: planck.Vec2(x, y),
            angle: options.angle || 0
        });

        this.body.createFixture(planck.Box(width / 2, height / 2), {
            density: options.density !== undefined ? options.density : 0,
            restitution: options.restitution !== undefined ? options.restitution : 1
        });

        // BASS
        // Add these lines
        this.bassSynth = new Tone.MonoSynth({
            oscillator: { type: "sawtooth" },
            envelope: { attack: 0.01, decay: 0.1, sustain: 0.3, release: 0.8 },
            filterEnvelope: {
                attack: 0.001,
                decay: 0.1,
                sustain: 0.4,
                release: 0.2,
                baseFrequency: 200,
                octaves: 2.6
            }
        }).toDestination();

        this.distortion = new Tone.Distortion(0.3).toDestination();
        this.compressor = new Tone.Compressor(-30, 3).toDestination();

        this.bassSynth.connect(this.distortion);
        this.distortion.connect(this.compressor);
    }

    draw(p) {
        let position = this.body.getPosition();
        let angle = this.body.getAngle();
        p.fill(this.options.color || 'white');
        p.noStroke();
        p.push();
        p.translate(position.x * this.scale, position.y * this.scale);
        p.rotate(angle);
        p.rectMode(p.CENTER);
        p.rect(0, 0, this.width * this.scale, this.height * this.scale);

        // Draw lives if breakable
        if (this.originalLives > 0) {
            p.fill(0);
            p.textAlign(p.CENTER, p.CENTER);
            p.text(this.lives, 0, 0);
        }

        p.pop();
    }

    hit() {
        if (this.lives > 0) {
            this.lives--;
            // Change color based on remaining lives
            const hue = Math.floor(120 * (this.lives / this.originalLives));
            this.options.color = `hsl(${hue}, 100%, 50%)`;
            return this.lives === 0;  // Return true if it should be removed
        }
        return false;
    }

    reset() {
        this.lives = this.originalLives;
        this.options.color = this.originalColor;
    }

    playSound() {
        if (this.sound) { // Only play if a sound is specified
            switch (this.soundType) {
                case 'drum':
                    this.playDrumSound();
                    break;
                case 'bounce':
                    this.playBounceSound();
                    break;
                case 'bass':
                    this.playBassSound();
                    break;
                case 'tone':
                default:
                    this.playToneSound();
                    break;
            }
        }
    }

    playToneSound() {
        console.log("play tone");
        const synth = new Tone.Synth({
            oscillator: { type: 'sine' },
            envelope: { attack: 0.005, decay: 0.1, sustain: 0.3, release: 0.4 }
        }).toDestination();
        synth.triggerAttackRelease(this.sound, '8n');
    }

    playDrumSound() {
        console.log("play drum");
        const drumType = this.sound;  // 'kick', 'snare', or 'hihat'

        let drumSynth;
        if (drumType === 'kick') {
            drumSynth = new Tone.MembraneSynth({
                pitchDecay: 0.05,
                octaves: 4,
                oscillator: { type: 'sine' },
                envelope: { attack: 0.001, decay: 0.4, sustain: 0.01, release: 1.4 }
            }).toDestination();
        } else if (drumType === 'snare') {
            drumSynth = new Tone.NoiseSynth({
                noise: { type: 'white' },
                envelope: { attack: 0.001, decay: 0.2, sustain: 0.01, release: 0.5 }
            }).toDestination();
        } else if (drumType === 'hihat') {
            drumSynth = new Tone.MetalSynth({
                frequency: 200,
                envelope: { attack: 0.001, decay: 0.1, release: 0.1 },
                harmonicity: 5.1,
                modulationIndex: 32,
                resonance: 8000,
                octaves: 1.5
            }).toDestination();
            drumSynth.volume.value = 10;
        }

        // Trigger the drum sound
        if (drumSynth) {
            drumSynth.triggerAttackRelease('8n');
        }
    }

    playBounceSound() {
        const bounceSynth = new Tone.Synth({
            oscillator: { type: 'triangle' },
            envelope: { attack: 0.001, decay: 0.1, sustain: 0.1, release: 0.1 }
        }).toDestination();
        bounceSynth.triggerAttackRelease(this.sound, '16n');
    }

    playBassSound() {
        console.log("play bass");
        if (this.sound) {
            // Adjust the octave down for a deeper bass sound
            const deeperNote = Tone.Frequency(this.sound).transpose(-12);
            this.bassSynth.triggerAttackRelease(deeperNote, '8n');
        }
    }
    // const bassline = ['G3', 'D3', 'E3', 'G3', 'E3', 'D3', 'B2', 'D3'];
}