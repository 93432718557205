import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useP5} from '../hooks/useP5';
import {useNavigate, useParams} from 'react-router-dom';
import './LevelEditor.css';

function LevelEditor() {
    const [levelData, setLevelData] = useState({objects: []});
    const levelDataRef = useRef({objects: []});
    const [selectedObject, setSelectedObject] = useState(null);
    const {levelFile} = useParams();
    const scale = 10;
    const navigate = useNavigate();
    const [levelName, setLevelName] = useState('');

    useEffect(() => {
        if (levelFile) {
            // Check if it's a local storage level
            const localLevels = JSON.parse(localStorage.getItem('levels') || '{}');
            if (localLevels[levelFile]) {
                const localLevel = localLevels[levelFile];
                setLevelData(localLevel);
                levelDataRef.current = localLevel;
                setLevelName(levelFile);
            } else {
                // If not in local storage, fetch from server
                fetch(`/levels/${levelFile}`)
                    .then(response => response.json())
                    .then(data => {
                        const updatedData = {
                            ...data,
                            objects: data.objects.map(obj => ({
                                ...obj,
                                options: obj.options || {
                                    restitution: obj.restitution,
                                    color: obj.type === 'ball' ? '#FF0000' : '#FFFFFF',
                                    sound: null
                                }
                            }))
                        };
                        setLevelData(updatedData);
                        levelDataRef.current = updatedData;
                    })
                    .catch(error => console.error('Error loading level:', error));
            }
        }
    }, [levelFile]);

    const sketch = useCallback((p) => {
        let draggingObject = null;
        let draggingIndex = null;
        let offsetX, offsetY;

        p.setup = function () {
            p.createCanvas(900, 800);
        };

        p.draw = function () {
            p.background(240);
            drawGrid(p);
            drawObjects(p);
        };

        p.mousePressed = () => {
            const mouseX = p.mouseX / scale;
            const mouseY = p.mouseY / scale;

            for (let i = levelDataRef.current.objects.length - 1; i >= 0; i--) {
                const obj = levelDataRef.current.objects[i];
                if (obj.type === 'rectangle') {
                    if (isPointInRotatedRectangle(
                        mouseX, mouseY,
                        obj.x, obj.y,
                        obj.width, obj.height,
                        obj.options.angle || 0
                    )) {
                        draggingObject = obj;
                        draggingIndex = i;
                        offsetX = (mouseX - obj.x) * Math.cos(obj.options.angle || 0) + (mouseY - obj.y) * Math.sin(obj.options.angle || 0);
                        offsetY = -(mouseX - obj.x) * Math.sin(obj.options.angle || 0) + (mouseY - obj.y) * Math.cos(obj.options.angle || 0);
                        setSelectedObject(i);
                        break;
                    }
                } else if (obj.type === 'ball') {
                    const dx = mouseX - obj.x;
                    const dy = mouseY - obj.y;
                    if (dx * dx + dy * dy <= obj.radius * obj.radius) {
                        draggingObject = obj;
                        draggingIndex = i;
                        offsetX = dx;
                        offsetY = dy;
                        setSelectedObject(i);
                        break;
                    }
                }
            }
        };

        p.mouseDragged = () => {
            if (draggingObject) {
                const mouseX = p.mouseX / scale;
                const mouseY = p.mouseY / scale;

                if (draggingObject.type === 'rectangle') {
                    const angle = draggingObject.options.angle || 0;
                    const newX = mouseX - offsetX * Math.cos(angle) + offsetY * Math.sin(angle);
                    const newY = mouseY - offsetX * Math.sin(angle) - offsetY * Math.cos(angle);
                    draggingObject.x = newX;
                    draggingObject.y = newY;
                } else if (draggingObject.type === 'ball') {
                    draggingObject.x = mouseX - offsetX;
                    draggingObject.y = mouseY - offsetY;
                }

                const newObjects = [...levelDataRef.current.objects];
                newObjects[draggingIndex] = draggingObject;

                levelDataRef.current = {...levelDataRef.current, objects: newObjects};
                setLevelData(levelDataRef.current);
            }
        };

        p.mouseReleased = () => {
            draggingObject = null;
            draggingIndex = null;
        };

        const drawGrid = (p) => {
            p.stroke(200);
            p.strokeWeight(1);
            for (let x = 0; x <= p.width; x += scale) {
                p.line(x, 0, x, p.height);
            }
            for (let y = 0; y <= p.height; y += scale) {
                p.line(0, y, p.width, y);
            }
        };

        const drawObjects = (p) => {
            levelDataRef.current.objects.forEach((obj, index) => {
                if (obj.type === 'rectangle') {
                    p.fill(obj.options?.color || 'white');
                    p.stroke(index === selectedObject ? 'red' : 'black');
                    p.strokeWeight(index === selectedObject ? 2 : 1);
                    p.push();
                    p.translate(obj.x * scale, obj.y * scale);
                    if (obj.options && obj.options.angle) {
                        p.rotate(obj.options.angle);
                    }
                    p.rectMode(p.CENTER);
                    p.rect(0, 0, obj.width * scale, obj.height * scale);
                    p.pop();
                } else if (obj.type === 'ball') {
                    p.fill(obj.options?.color || 'red');
                    p.stroke(index === selectedObject ? 'red' : 'black');
                    p.strokeWeight(index === selectedObject ? 2 : 1);
                    p.ellipse(obj.x * scale, obj.y * scale, obj.radius * 2 * scale);
                }
            });
        };

    }, [selectedObject]);

    const p5ContainerRef = useP5(sketch);

    const addRectangle = () => {
        const newRectangle = {
            type: 'rectangle',
            x: 5,
            y: 5,
            width: 5,
            height: 1,
            options: {
                restitution: 1.0,
                color: '#000000',
                sound: null,
                angle: 0
            }
        };
        const newLevelData = {
            ...levelDataRef.current,
            objects: [...levelDataRef.current.objects, newRectangle]
        };
        levelDataRef.current = newLevelData;
        setLevelData(newLevelData);
    };

    const addBall = () => {
        const newBall = {
            type: 'ball',
            x: 5,
            y: 5,
            radius: 0.5,
            options: {
                restitution: 1,
                color: '#FF0000',
                sound: null
            }
        };
        const newLevelData = {
            ...levelDataRef.current,
            objects: [...levelDataRef.current.objects, newBall]
        };
        levelDataRef.current = newLevelData;
        setLevelData(newLevelData);
    };

    const saveLevel = () => {
        const saveName = prompt('Enter a name for this level:', levelName);
        if (saveName) {
            const levels = JSON.parse(localStorage.getItem('levels') || '{}');
            levels[saveName] = levelDataRef.current;
            localStorage.setItem('levels', JSON.stringify(levels));
            setLevelName(saveName);
            alert(`Level "${saveName}" saved successfully!`);
        }
    };

    const loadLevel = () => {
        const levels = JSON.parse(localStorage.getItem('levels') || '{}');
        const levelNames = Object.keys(levels);
        if (levelNames.length === 0) {
            alert('No saved levels found.');
            return;
        }
        const selectedLevel = prompt('Enter the name of the level to load:\n\n' + levelNames.join(', '));
        if (selectedLevel && levels[selectedLevel]) {
            setLevelData(levels[selectedLevel]);
            levelDataRef.current = levels[selectedLevel];
            setLevelName(selectedLevel);
        } else if (selectedLevel) {
            alert('Level not found.');
        }
    };

    const saveAndPlay = () => {
        // Capture current state
        const simulationState = {
            objects: levelData.objects.map(obj => ({
                ...obj,
                body: null // We don't want to save the planck.js body
            }))
        };

        // Save to localStorage
        localStorage.setItem('savedSimulation', JSON.stringify(simulationState));

        // Redirect to simulation page
        navigate('/simulation');
    };

    const updateSelectedObject = (property, value) => {
        if (selectedObject !== null) {
            const updatedObjects = [...levelDataRef.current.objects];
            if (property.startsWith('options.')) {
                const optionProperty = property.split('.')[1];
                updatedObjects[selectedObject].options[optionProperty] = value;
            } else {
                updatedObjects[selectedObject][property] = value;
            }
            const newLevelData = {...levelDataRef.current, objects: updatedObjects};
            levelDataRef.current = newLevelData;
            setLevelData(newLevelData);
        }
    };

    const isPointInRotatedRectangle = (px, py, rectX, rectY, rectWidth, rectHeight, angle) => {
        const translatedX = px - rectX;
        const translatedY = py - rectY;
        const rotatedX = translatedX * Math.cos(-angle) - translatedY * Math.sin(-angle);
        const rotatedY = translatedX * Math.sin(-angle) + translatedY * Math.cos(-angle);
        return Math.abs(rotatedX) <= rectWidth / 2 && Math.abs(rotatedY) <= rectHeight / 2;
    };

    return (
        <div className="level-editor">
            <h1>Level Editor {levelName && `- ${levelName}`}</h1>
            <div className="editor-layout">
                <div ref={p5ContainerRef} className="canvas-container"></div>
                <div className="controls">
                    <button onClick={addRectangle}>Add Rectangle</button>
                    <button onClick={addBall}>Add Ball</button>
                    <button onClick={saveLevel}>Save Level</button>
                    <button onClick={loadLevel}>Load Level</button>
                    <button onClick={saveAndPlay}>Save and Play</button>
                    {selectedObject !== null && (
                        <div className="object-properties">
                            <h3>{levelData.objects[selectedObject].type.charAt(0).toUpperCase() + levelData.objects[selectedObject].type.slice(1)} Properties</h3>
                            {levelData.objects[selectedObject].type === 'rectangle' && (
                                <>
                                    <label>
                                        Width:
                                        <input
                                            type="number"
                                            value={levelData.objects[selectedObject].width}
                                            onChange={(e) => updateSelectedObject('width', parseFloat(e.target.value))}
                                        />
                                    </label>
                                    <label>
                                        Height:
                                        <input
                                            type="number"
                                            value={levelData.objects[selectedObject].height}
                                            onChange={(e) => updateSelectedObject('height', parseFloat(e.target.value))}
                                        />
                                    </label>
                                    <label>
                                        Angle:
                                        <input
                                            type="number"
                                            value={levelData.objects[selectedObject].options.angle * (180 / Math.PI)}
                                            onChange={(e) => updateSelectedObject('options.angle', parseFloat(e.target.value) * (Math.PI / 180))}
                                        />
                                    </label>
                                </>
                            )}
                            {levelData.objects[selectedObject].type === 'ball' && (
                                <label>
                                    Radius:
                                    <input
                                        type="number"
                                        value={levelData.objects[selectedObject].radius}
                                        onChange={(e) => updateSelectedObject('radius', parseFloat(e.target.value))}
                                    />
                                </label>
                            )}
                            <label>
                                Color:
                                <input
                                    type="color"
                                    value={levelData.objects[selectedObject].options.color}
                                    onChange={(e) => updateSelectedObject('options.color', e.target.value)}
                                />
                            </label>
                            <label>
                                Restitution:
                                <input
                                    type="number"
                                    min="0"
                                    max="1"
                                    step="0.1"
                                    value={levelData.objects[selectedObject].options.restitution}
                                    onChange={(e) => updateSelectedObject('options.restitution', parseFloat(e.target.value))}
                                />
                            </label>
                            <label>
                                Sound:
                                <input
                                    type="text"
                                    value={levelData.objects[selectedObject].options.sound || ''}
                                    onChange={(e) => updateSelectedObject('options.sound', e.target.value)}
                                />
                            </label>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default LevelEditor;