import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './SimulationList.css';

function SimulationList() {
    const [simulations, setSimulations] = useState([]);
    const [userLevels, setUserLevels] = useState({});

    useEffect(() => {
        // Fetch pre-made simulations
        fetch('/simulations/simulations.json')
            .then(response => response.json())
            .then(data => setSimulations(data))
            .catch(error => console.error('Error loading simulations:', error));

        // Load user-created levels from local storage
        const storedLevels = JSON.parse(localStorage.getItem('levels') || '{}');
        setUserLevels(storedLevels);
    }, []);

    return (
        <div className="simulation-list-container">
            <h2 className="section-title">Featured Simulations</h2>
            <div className="simulation-grid">
                {simulations.map(sim => (
                    <div key={sim.file} className="simulation-card">
                        {sim.youtubeId ? (
                            <a href={`https://www.youtube.com/watch?v=${sim.youtubeId}`} target="_blank" rel="noopener noreferrer" className="video-link">
                                <img src={`https://img.youtube.com/vi/${sim.youtubeId}/0.jpg`} alt={sim.name} className="simulation-thumbnail" />
                                <div className="play-icon">▶</div>
                            </a>
                        ) : (
                            <div className="video-link placeholder-thumbnail">
                                <div className="coming-soon-text">Video Coming Soon</div>
                            </div>
                        )}
                        <div className="simulation-info">
                            <h3>{sim.name}</h3>
                            <p>{sim.description}</p>
                            <div className="button-group">
                                <Link to={`/simulation/${sim.file}`} className="primary-button">Run Simulation</Link>
                                <Link to={`/editor/${sim.file}`} className="secondary-button">Edit</Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {Object.keys(userLevels).length > 0 && (
                <>
                    <h2 className="section-title">Your Created Levels</h2>
                    <div className="simulation-grid">
                        {Object.entries(userLevels).map(([levelName, levelData]) => (
                            <div key={levelName} className="simulation-card">
                                <div className="simulation-info">
                                    <h3>{levelName}</h3>
                                    <p>Custom created level</p>
                                    <div className="button-group">
                                        <Link to={`/simulation/${encodeURIComponent(levelName)}`} className="primary-button">Run Simulation</Link>
                                        <Link to={`/editor/${encodeURIComponent(levelName)}`} className="secondary-button">Edit</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}

            <Link to="/editor" className="create-button">Create New Simulation</Link>
        </div>
    );
}

export default SimulationList;