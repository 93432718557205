import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import p5 from 'p5';
import * as planck from 'planck-js';
import * as Tone from 'tone';
import { Rectangle } from './Rectangle';
import { Ball } from './Ball';
import './Simulation.css';

function Simulation() {
    const { levelFile } = useParams();
    const [levelData, setLevelData] = useState(null);
    const canvasRef = useRef(null);
    const worldRef = useRef(null);
    const synth = useRef(null);
    const [isRunning, setIsRunning] = useState(false);
    const objectsRef = useRef([]);
    const objectsToRemoveRef = useRef([]);

    useEffect(() => {
        if (!isRunning && objectsRef.current) {
            objectsRef.current.forEach(obj => {
                if (obj.type === 'rectangle' && obj.reset) {
                    obj.reset();
                }
            });
        }
    }, [isRunning]);

    useEffect(() => {
        const loadLevel = () => {
            console.log("load level")
            if (levelFile) {
                // Check if it's a local storage level
                const localLevels = JSON.parse(localStorage.getItem('levels') || '{}');
                console.log("loading specific level");
                if (localLevels[levelFile]) {
                    console.log("Level data from local storage:", localLevels[levelFile]);
                    setLevelData(localLevels[levelFile]);
                } else {
                    // If not in local storage, fetch from server
                    fetch(`/levels/${levelFile}`)
                        .then(response => response.json())
                        .then(data => {
                            setLevelData({
                                ...data,
                                gravity: data.gravity || undefined
                            });
                        })
                        .catch(error => console.error('Error loading level:', error));
                }
            } else {
                const savedSimulation = localStorage.getItem('savedSimulation');
                if (savedSimulation) {
                    setLevelData(JSON.parse(savedSimulation));
                }
            }
        };

        loadLevel();
    }, [levelFile]);

    useEffect(() => {
        if (!levelData) return;

        const scale = 10;
        let sketch = function(p) {
            let world;

            p.setup = function() {
                p.createCanvas(900, 800);
                world = planck.World({
                    gravity: planck.Vec2(levelData.gravity?.x ?? 0, levelData.gravity?.y ?? 10)
                });
                worldRef.current = world;
                synth.current = new Tone.PolySynth().toDestination();

                objectsRef.current = levelData.objects.map(obj => {
                    console.log(obj)
                    if (obj.type === 'rectangle') {
                        return new Rectangle(world, obj.x, obj.y, obj.width, obj.height, obj.options, scale);
                    } else if (obj.type === 'ball') {
                        return new Ball(world, obj.x, obj.y, obj.radius, obj.options, scale);
                    }
                    return null;
                }).filter(obj => obj !== null);

                world.on('begin-contact', function(contact) {
                    let fixtureA = contact.getFixtureA();
                    let fixtureB = contact.getFixtureB();
                    let bodyA = fixtureA.getBody();
                    let bodyB = fixtureB.getBody();

                    let rectangleObj = objectsRef.current.find(obj =>
                        (obj.body === bodyA || obj.body === bodyB) && obj.type === 'rectangle'
                    );

                    if (rectangleObj) {
                        rectangleObj.playSound(synth.current);
                        if (rectangleObj.hit()) {
                            objectsToRemoveRef.current.push(rectangleObj);
                        }
                    }
                });
            };

            p.draw = function() {
                p.background(240);

                if (isRunning) {
                    // Remove objects queued for removal
                    while (objectsToRemoveRef.current.length > 0) {
                        const objToRemove = objectsToRemoveRef.current.pop();
                        world.destroyBody(objToRemove.body);
                        objectsRef.current = objectsRef.current.filter(obj => obj !== objToRemove);
                    }

                    world.step(1 / 60);
                }

                objectsRef.current.forEach(obj => {
                    obj.draw(p);
                });
            };
        };

        const p5Instance = new p5(sketch, canvasRef.current);

        return () => {
            p5Instance.remove();
            if (synth.current) {
                synth.current.dispose();
            }
        };
    }, [levelData, isRunning]);

    const toggleSimulation = () => {
        setIsRunning(!isRunning);
    };

    if (!levelData) return <div>Loading...</div>;

    return (
        <div className="simulation-container">
            <div className="simulation-content">
                <button onClick={toggleSimulation} className="simulation-button">
                    {isRunning ? 'Stop' : 'Start'} Simulation
                </button>
                <div ref={canvasRef} className="simulation-canvas"></div>
            </div>
        </div>
    );
}

export default Simulation;