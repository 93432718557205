import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SimulationList from './components/SimulationList';
import LevelEditor from './components/LevelEditor';
import Simulation from './components/Simulation';
import Header from './components/Header';

function App() {
    return (
        <Router>
            <div className="app-container">
                <Header />
                <Routes>
                    <Route path="/" element={<SimulationList />} />
                    <Route path="/editor" element={<LevelEditor />} />
                    <Route path="/editor/:levelFile" element={<LevelEditor />} />
                    <Route path="/simulation" element={<Simulation />} />
                    <Route path="/simulation/:levelFile" element={<Simulation />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;